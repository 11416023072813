(function(window) { var svs = window.svs || {}; svs.modules = svs.modules || []; if(svs.modules.indexOf('/components/sportinfo/game-analyses-redux/assets/game-analyses-selectors.js') >= 0) return;  svs.modules.push('/components/sportinfo/game-analyses-redux/assets/game-analyses-selectors.js');
"use strict";


const {
  ReducerState
} = svs.components.sport.tipsenShared;
const drawKeyFromAction = (productId, drawNumber) => "".concat(productId, "_").concat(drawNumber);
const selectAuthorProfiles = state => state.gameAnalyses.authorProfiles;
const selectAuthorProfile = (state, authorId) => state.gameAnalyses.authorProfiles[authorId];
const selectDrawAnalyses = state => state.gameAnalyses.drawAnalyses;
const selectAnalysesByDraw = state => state.gameAnalyses.byDraw;
const selectMatchAnalyses = state => state.gameAnalyses.matchAnalyses;
const selectAnalysesByMatch = (state, matchId) => state.gameAnalyses.byMatchId[matchId];
const selectMatchAnalysis = (state, analysisId) => state.gameAnalyses.matchAnalyses[analysisId];
const selectDrawAnalysesIsDone = (state, productId, drawNumber) => state.gameAnalyses.drawAnalysesState[drawKeyFromAction(productId, drawNumber)] === ReducerState.Done;
const selectDrawAnalysesIsRejected = (state, productId, drawNumber) => state.gameAnalyses.drawAnalysesState[drawKeyFromAction(productId, drawNumber)] === ReducerState.Rejected;
const selectDrawAnalysesShouldShowSkeleton = (state, productId, drawNumber) => !selectDrawAnalysesIsDone(state, productId, drawNumber) && !selectDrawAnalysesIsRejected(state, productId, drawNumber);
const selectAllMatchAnalyseIdsForSpecificDraws = (state, drawIds) => {
  const currentDrawMatchAnalyseIds = [];
  drawIds.forEach(drawId => {
    currentDrawMatchAnalyseIds.push(state.gameAnalyses.drawAnalyses[drawId].gameAnalysesIds);
  });
  return currentDrawMatchAnalyseIds;
};
const selectAllMatchAnalysesByMatchIdAndEventNumber = (state, matchId, eventNumber) => {
  var _state$gameAnalyses;
  const allAnalysesByMatchId = (_state$gameAnalyses = state.gameAnalyses) !== null && _state$gameAnalyses !== void 0 && _state$gameAnalyses.byMatchId ? state.gameAnalyses.byMatchId[matchId] : undefined;
  const allAnalysesByMatchIdAndEventNumber = [];
  if (allAnalysesByMatchId && allAnalysesByMatchId.length > 0) {
    allAnalysesByMatchId.forEach(analyseId => {
      const currentAnalyse = state.gameAnalyses.matchAnalyses[analyseId];
      if (currentAnalyse.eventNumber === eventNumber) {
        allAnalysesByMatchIdAndEventNumber.push(analyseId);
      }
    });
  }
  return allAnalysesByMatchIdAndEventNumber;
};
const selectMatchAnalysIdsForSpecificDrawAndMatchAndEventNumber = function (state, productId, drawNumber, matchId) {
  let eventNumber = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : undefined;
  const analyseDrawIds = state.gameAnalyses.byDraw[drawKeyFromAction(productId, drawNumber)];
  let allPossibleMatchAnalyseIds = [];
  if (analyseDrawIds && analyseDrawIds.length > 0) {
    allPossibleMatchAnalyseIds = selectAllMatchAnalyseIdsForSpecificDraws(state, analyseDrawIds);
  }
  let allGameAnalyseMatchIds = [];
  if (eventNumber) {
    allGameAnalyseMatchIds = selectAllMatchAnalysesByMatchIdAndEventNumber(state, matchId, eventNumber);
  } else {
    var _state$gameAnalyses2;
    allGameAnalyseMatchIds = (_state$gameAnalyses2 = state.gameAnalyses) !== null && _state$gameAnalyses2 !== void 0 && _state$gameAnalyses2.byMatchId ? state.gameAnalyses.byMatchId[matchId] : undefined;
  }
  const idsToUse = [];
  if (allPossibleMatchAnalyseIds && allGameAnalyseMatchIds) {
    allPossibleMatchAnalyseIds.forEach(analyseIdsForDraws => {
      analyseIdsForDraws.forEach(analyseIdForDraw => {
        const matchingId = allGameAnalyseMatchIds.find(analyseIdForMatch => analyseIdForMatch === analyseIdForDraw);
        if (matchingId) {
          idsToUse.push(matchingId);
        }
      });
    });
  }
  return idsToUse;
};
const selectParticipantNamesByMatchId = (state, matchId) => state.gameAnalyses.drawData[matchId];
const selectAllMatchIdsFromAnalyses = state => {
  const allMatchIds = [];
  for (const [, value] of Object.entries(state.gameAnalyses.matchAnalyses)) {
    const matchIds = value.gameInfo.sportEvents;
    for (const matchId of matchIds) {
      if (!allMatchIds.includes(matchId)) {
        allMatchIds.push(matchId);
      }
    }
  }
  return allMatchIds;
};
setGlobal('svs.components.sportinfo.gameAnalysesRedux.selectors', {
  selectAuthorProfiles,
  selectAuthorProfile,
  selectAnalysesByDraw,
  selectDrawAnalyses,
  selectMatchAnalyses,
  selectAnalysesByMatch,
  selectDrawAnalysesIsDone,
  selectDrawAnalysesIsRejected,
  selectDrawAnalysesShouldShowSkeleton,
  selectMatchAnalysis,
  selectMatchAnalysIdsForSpecificDrawAndMatchAndEventNumber,
  selectParticipantNamesByMatchId,
  selectAllMatchIdsFromAnalyses
});

 })(window);